.logo-content{
    line-height: 2;
    font-size: 2vmax;
  }

  .footer-container {
    background-color: #EF972D;
    background-image: linear-gradient(180deg, #EF972D 4%, #ffffff 26%);
  }
  
  .footer-logo {
    font-size: 2.7vw;
    text-decoration: none;
    font-weight: 600;
    color: black;
  }
  
  .footer-section {
    margin-top: 5vh;
  }

  .footer-heading{
    font-size: 2.2vw;
    color: black;
  }
  
  .footer,a {
    text-decoration: none !important;
    color: black !important;
    font-size: 1.7vmin;
  }

  footer,p{
    font-size: 1.2vmax;
  }
  .copy-text{
    font-size: 12px;
  }
  
  footer,
  li {
    line-height: 2.5;
    text-align: left;
    font-size: 1.7vmin;
    margin-bottom: 1px;
  }
  
  a:hover {
    color: #D30100 !important;
  }
  
  ul,
  .footer-list {
    margin-bottom: 10px;
    list-style-image: url("testTube.png");
  }
  
  .footer-legal {
    list-style: none;
  }
  
  .footer-legal > li {
    display: inline-block;
    margin-right: 4vmin;
  }
  
  .social {
    align-items: right;
    display: inline-block;
  }
  @media screen and (max-width: 750px){
    footer,li,a{
      font-size: 2.2vmin;
    }
    .copy-text{
      font-size: 2vw;
    }
    .footer-heading{
      font-size: 3.5vw;
    }
  }