.main-heading {
  font-size: 5vw !important ;
  color: black;
}
.about-text {
  font-size: 1.2vw;
  text-align: justify !important;
  text-justify: inter-word !important;
}
.why-list li {
  font-size: 1.2vw !important;
  line-height: 2;
}
.portfolio {
  height: auto;
  width: 35vw;
}
.what-do-we-do,
dd {
  color: black;
  font-size: 1vw;
  text-align: justify !important;
  border-radius: 25px;
}
p.why-us {
  width: 60vw;
}
.whyus-img {
  width: 25vw;
}
.headings {
  font-size: 2.2vw;
}
@media screen and (max-width: 750px) {
  .about-text {
    width: 100%;
    font-size: 3.5vw;
    text-align: justify;
    text-justify: inter-word;
  }
  .headings {
    font-size: 5vw;
    padding-bottom: 2vh;
    text-align: center;
  }
  .why-list li {
    font-size: 3.5vw !important;
  }
  .portfolio {
    height: auto;
    width: 65vw;
  }
  .whyus-img {
    width: 50vw;
  }
}
